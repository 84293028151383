/* shared-styles.css */
.heading {
  font-size: 24px;
  font-weight: bold;
}
.subheading {
  font-size: 20px;
  font-weight: bold;
}
.subheading-2 {
  font-size: 18px;
  font-weight: bold;
}
.text {
  font-size: 16px;
  font-weight: normal;
  color: #718096;
}
.help-text {
  font-size: 12px;
  font-weight: normal;
}
.numberheading {
  font-size: 20px;
  font-weight: bold;
}

.bg-opacity-90 {
  --bg-opacity: 0.9;
}
.text-btn {
  font-weight: bold;
  font-size: 18px;
  text-transform: none;
}
.selText{
  font-size: 30px;
  color: grey;
  text-transform: none;
  font-weight:bold;
}
